.font-0 {
  font-family: "Eiko";
}

.font-1 {
  font-family: "Neue Machina";
}

.font-2 {
  font-family: "Migra";
}

.wght-0 {
  font-weight: 100;
}

.wght-1 {
  font-weight: 200;
}

.wght-2 {
  font-weight: 300;
}

.wght-3 {
  font-weight: 400;
}

.wght-4 {
  font-weight: 500;
}

.wght-5 {
  font-weight: 600;
}

.wght-6 {
  font-weight: 700;
}

.wght-7 {
  font-weight: 800;
}

.wght-8 {
  font-weight: 900;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.5rem;
  margin: 5rem auto;
  line-height: 100%;
}

h3 {
  font-size: 1.25rem;
  margin: 0;
}
