.trigger {
  border-bottom: 1px solid var(--text);
}

.trigger:hover {
  cursor: pointer;
}

.image__container {
  margin-top: -2rem;
  z-index: -1;
}
