nav {
  padding-top: 2rem;
}

.link-grid {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}
