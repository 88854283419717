.align-top {
  align-self: flex-start;
}

.align-middle {
  align-self: center;
}

.align-bottom {
  align-self: flex-end;
}

.nav-link {
  line-height: 1em;
}
