.layout__container {
  display: grid;
  max-width: calc(65ch + 16px);
  padding: 0 16px;
  margin: 0 auto;
}

.grid-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-start-auto {
  grid-column-start: auto;
}

.grid-end-auto {
  grid-column-end: auto;
}

.grid-start-1 {
  grid-column-start: 1;
}

.grid-end-1 {
  grid-column-end: 1;
}

.grid-start-2 {
  grid-column-start: 2;
}

.grid-end-2 {
  grid-column-end: 2;
}

.grid-start-3 {
  grid-column-start: 3;
}

.grid-end-3 {
  grid-column-end: 3;
}

.grid-start-4 {
  grid-column-start: 4;
}

.grid-end-4 {
  grid-column-end: 4;
}

.grid-start-5 {
  grid-column-start: 5;
}

.grid-end-5 {
  grid-column-end: 5;
}

.grid-start-6 {
  grid-column-start: 6;
}

.grid-end-6 {
  grid-column-end: 6;
}

.grid-start-7 {
  grid-column-start: 7;
}

.grid-end-7 {
  grid-column-end: 7;
}

.grid-start-8 {
  grid-column-start: 8;
}

.grid-end-8 {
  grid-column-end: 8;
}

.grid-start-9 {
  grid-column-start: 9;
}

.grid-end-9 {
  grid-column-end: 9;
}

.grid-row-start-1 {
  grid-row-start: 1;
}

.grid-row-end-1 {
  grid-row-end: 1;
}

.grid-row-start-2 {
  grid-row-start: 2;
}

.grid-row-end-2 {
  grid-row-end: 2;
}

.grid-row-start-3 {
  grid-row-start: 3;
}

.grid-row-end-3 {
  grid-row-end: 3;
}

.grid-row-start-4 {
  grid-row-start: 4;
}

.grid-row-end-4 {
  grid-row-end: 4;
}

.grid-row-start-5 {
  grid-row-start: 5;
}

.grid-row-end-5 {
  grid-row-end: 5;
}

.grid-row-start-6 {
  grid-row-start: 6;
}

.grid-row-end-6 {
  grid-row-end: 6;
}

.grid-row-start-7 {
  grid-row-start: 7;
}

.grid-row-end-7 {
  grid-row-end: 7;
}

.grid-row-start-8 {
  grid-row-start: 8;
}

.grid-row-end-8 {
  grid-row-end: 8;
}

.grid-row-start-9 {
  grid-row-start: 9;
}

.grid-row-end-9 {
  grid-row-end: 9;
}
