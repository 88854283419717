body {
  margin: 0;
  font-family: "Eiko", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--bg);
  color: var(--text);
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: var(--text);
  text-decoration: none;
  border-bottom: 1px solid var(--text);
}

img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

:root {
  --text: #180000;
  --bg: #fcf2f2;
}
